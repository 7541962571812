<template>
  <div class="d-flex flex-column pa-2 mt-4 full-width">
    <div class="d-flex align-bottom">
      <v-text-field
        class="mx-2 start-at"
        :label="$t('t.StartAt')"
        :value="startAt"
        readonly
      />
      <div
        v-if="launchBy"
        class="d-flex flex-column"
      >
        <span class="caption mb-1">{{toPascalCase($t('t.By'))}}</span>
        <user-ref
          block-popup
          hide-group
          hide-email
          hide-phone
          :avatar-props="{xsmall: true}"
          :id="launchBy"
        />
      </div>
      <v-spacer />

      <v-tooltip
        top
        open-delay="200"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="downloadArchive"
            icon
          ><v-icon>{{$icon('i.Download')}}</v-icon>
          </v-btn>
        </template>
        <span>{{$t('t.DownloadImportArchive')}}</span>
      </v-tooltip>
    </div>
    <grid
      item-min-width="300px"
      class="align-center"
      v-if="hasErrors"
    >
      <span
        v-for="(error, idx) in errors"
        :key="idx"
        class="mx-2 error--text"
      >
        {{error}}
      </span>
    </grid>
    <import-recap
      v-else
      class="recap"
      :import-id="importId"
      :data-sources-summary="dataSources"
    />
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
export default {
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.ImportRun')
    })
    this.updateData()
  },
  components: {
    Grid: () => import('@/components/grid'),
    ImportRecap: () => import('../import/import-recap'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    hasErrors () {
      return this.data?.errors.length
    },
    errors () {
      return this.data?.errors
    },
    dataSources () {
      return this.data?.dataSources ?? {}
    },
    startAt () {
      return this.formatDateTime(this.data?.startingDate)
    },
    launchBy () {
      return this.data?.launchBy
    }
  },
  data () {
    return {
      data: null,
      importId: this.$route.params.id
    }
  },
  methods: {
    async downloadArchive () {
      const { data, headers } = await this.$http().get(`core/import/archive/${this.importId}`, { responseType: 'blob' })

      let filename = ''
      const disposition = headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }
      fileDownload(data, filename)
    },
    async updateData () {
      try {
        ({ data: this.data } = await this.$http().get(`/core/run/import/${this.importId}`))
      } catch (e) {
        if (e.response.status !== 404) {
          console.log(e)
        }
      }
    }
  },
  async mounted () {
    this.updateData()
  }
}
</script>
<style lang="stylus" scoped>
.recap
  height 500px

.full-width
  width 100%

.v-text-field
  min-width 250px

.start-at
  max-width 200px
</style>
